import * as commonConstants from "~/common/js/constants"

// ********************************************************
//   Centralised Constant repository for MyTributes
// ********************************************************

export const DATE_FORMAT = 'DD-MM-YYYY';

// Body classes to add for when various UI elements are toggled
export const SHOW_REFINEMENT_CLASS = commonConstants.SHOW_REFINEMENT_CLASS;
export const HAS_SUB_MENU = 'mobile-navigation_more';

//fullstory
export const LIGHT_CANDLE_EVENT_NAME = 'light-a-candle';

// Mobile search
export const searchInputEl = commonConstants.searchInputEl;
export const formExpandEl = commonConstants.formExpandEl;
export const mobileSearchTitleEl = commonConstants.mobileSearchTitleEl;
export const mobileSearchInputEl = commonConstants.mobileSearchInputEl;
export const closeMobileFormEl = commonConstants.closeMobileFormEl;
export const searchFormEl = commonConstants.searchFormEl;
export const searchIconEl = commonConstants.searchIconEl;
export const documentBodyEl = commonConstants.documentBodyEl;

// Refinement filters
export const refineEl = commonConstants.refineEl;
export const drawerEl = commonConstants.drawerEl;
export const refineCloseEl = commonConstants.refineCloseEl;
export const startDateEls = document.querySelectorAll('input[name="start"]');

// menu nav
export const menunav = document.querySelector(".nav-item");
export const menunavEl = document.querySelector(".nav-item ul");
export const mobilemenuEl = document.querySelector(".menu-item-holder");

// For recent notice homepage
export const noticeCategoryDisplayName = {
  'death-notices': 'Death Notices',
  'funeral-notices': 'Funeral Notices',
  'condolences': 'Tributes & Condolences'
}

// light a candle
export const lightcandle = {
  candleEL: document.querySelector(".candle"),
  mbCandleEL: document.querySelector(".mb-candle"),
  adobeTrackerEL: document.querySelectorAll(".light-candle"),
  candleCount: document.querySelectorAll(".candle-count"),
  HAS_CANDLE_LIT: 'light-candle-lit',
  HAS_CANDLE: 'candle',
  HAS_MB_CANDLE: 'mb-candle',
  submitEl: document.getElementById('candle_recaptcha'),
  recaptchaToken: document.getElementById('candle_recaptchaToken'),
  togglecontentEl: document.querySelectorAll('.candle-sf-txt'),
  lit: false,
  URL: 'lit-candle/'
}

// obituaries search placeholder
export const obPlaceholder = {
  lg: 'Search obituaries & eulogies',
  default: 'Search'
}
export const articlesSearchFilters = {
  articlesfilterkey: 'child_category_parent_id',
  articlesmbfilterEl: 'articles-mb-filter',
  formselectitemEl: 'form-select-item',
  goButtonEl: 'goButton-visible',
  extendsearch: 'extendsearch',
  articleslistingEl: document.querySelector('.articles-listing'),
  allTypes: 'all-types',
  allCategories: 'all-category-type'
}

// funeral directors
export const funeralDirectorsEl = {
  classvariables: {
    lg: 'fd-show-error-lg',
    md: 'fd-show-error-md'
  },
  elementlist: {
    lg: document.getElementById('fd-contact-alert-lg'),
    md: document.getElementById('fd-contact-alert-md'),
    success: document.getElementById('fd-show-alert-success')
  },
  fdElement: document.querySelector(".fd-contact-alert")
}

// pikaday element
export const pikadayEl = {
  classList: 'date-calendar',
  fromCalenderEl: document.getElementById("calendarModalToLeft"),
  toCalenderEl: document.getElementById("calendarModalToRight")
}

// common script to scale down below 428
export const lay = document.querySelector('header');
export const parentele = document.querySelector('body');
export const defaultwidth = 428;

// whitelabels
export const search = document.querySelector('.mb-search');
export const stickyheader = document.getElementById("stickyheader");
export const header = document.getElementById("header");
export const mbfilter = document.getElementById("filterButton_mobile");
export const ww = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
export const wh = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
export const hamburgerEl = document.querySelector('.hamburger');
export const obExtendedEl = document.querySelector('.obituaries-extend-search');
export const whitelabels = {
  sticky: false,
  stop: 212,
  defaulttop: 0,
  fixedtop: 65,
  fixed: 'fixed',
  absolute: 'absolute',
  relative: 'relative',
  unset: 'unset',
  hamburger: false,
  viewports: {
    sm: 428,
    md: 768,
    lg: 1280,
    xl: 1920
  }
}
