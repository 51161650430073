import { FuneralDirectorMap } from './FuneralDirectorMap'

document.addEventListener('DOMContentLoaded', () => {
  let isMapLoaded

  const showMapButton = document.querySelector('.map-show-button')
  const mapContainer = document.querySelector('.map-container')
  if (showMapButton && mapContainer) {
    const hideMapButton = mapContainer.querySelector('.map-hide-button')

    const fdFeatureContent = document.querySelector('.fd-feature-content')
    const fdStdContent = document.querySelector('.fd-std-content')

    showMapButton.addEventListener('click', () => {
      mapContainer.classList.remove('close-map-animation')
      mapContainer.classList.remove('hide')
      mapContainer.classList.add('open-map-animation')

      fdStdContent.classList.remove('fd-slide-up')
      fdStdContent.classList.add('fd-slide-down')
      fdFeatureContent.classList.remove('fd-slide-up')
      fdFeatureContent.classList.add('fd-slide-down')

      showMapButton.classList.add('hide')

      if (!isMapLoaded) {
        FuneralDirectorMap()
        isMapLoaded = true
      }
    })

    hideMapButton.addEventListener('click', () => {
      mapContainer.classList.remove('open-map-animation')
      mapContainer.classList.add('close-map-animation')
      setTimeout(() => mapContainer.classList.add('hide'), 250)

      fdStdContent.classList.remove('fd-slide-down')
      fdStdContent.classList.add('fd-slide-up')
      fdFeatureContent.classList.remove('fd-slide-down')
      fdFeatureContent.classList.add('fd-slide-up')

      showMapButton.classList.remove('hide')
    })
  }
})
