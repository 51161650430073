import Pikaday from 'pikaday/pikaday.js';
import dayjs from 'dayjs';

import { DATE_FORMAT, pikadayEl } from './constants';

// Dayjs Plugin
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

export default class DatePicker {

    constructor(startDateEl) {
        // Date ranges
        this.startDateEl = startDateEl;
        this.endDateEl = this.startDateEl.nextElementSibling;
        // some pages dont have the refinement filters
        if (this.startDateEl && this.endDateEl) {
            this.init();
        }
    }

    init() {

        let fromField = this.startDateEl;
        let toField = this.endDateEl;

        let todaysDate = new Date();

        // Check if fields have value on load, and set start and end dates accordingly
        let startDate = null;
        if (fromField.value) {
            startDate = dayjs(fromField.value, DATE_FORMAT).toDate();
        }

        let endDate = null;
        if (toField.value) {
            endDate = dayjs(toField.value, DATE_FORMAT).toDate();
        }

        var fromPicker = new Pikaday({
            field: fromField,
            container: (this.startDateEl.parentNode.className == pikadayEl.classList)?  pikadayEl.fromCalenderEl: '',
            yearRange: 25,
            defaultDate: startDate,
            setDefaultDate: Boolean(startDate),
            format: 'DD-MM-YYYY',
            maxDate: todaysDate,
            toString(date, format) {
                return dayjs(date).format(DATE_FORMAT);
            },
            onOpen: function() {
                if (toField.value) {
                    // Set end date on open because Chrome aggressively caches JS
                    var toEndDate = dayjs(toField.value, DATE_FORMAT).toDate();
                    fromPicker.setMaxDate(toEndDate);
                }
            },
            onSelect: function() {
                var selectedDate = new Date(this.getDate());
                var minEndDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
                toPicker.setMinDate(minEndDate);
                if (!toField.value && fromField.value) {
                    toPicker.setDate(todaysDate); // Changed this to today's date as this is the most likely desired result rather than just one day ahead
                }
            }
        });

        var toPicker = new Pikaday({
            field: toField,
            container: (this.startDateEl.parentNode.className == pikadayEl.classList)? pikadayEl.toCalenderEl : '',
            yearRange: 25,
            defaultDate: endDate,
            maxDate: todaysDate,
            minDate: startDate,
            setDefaultDate: true,
            format: 'DD-MM-YYYY',
            toString(date, format) {
                return dayjs(date).format(DATE_FORMAT);
            },
            onOpen: function() {
                if (fromField.value) {
                    // Set start date on open because Chrome aggressively caches JS
                    var startDate = dayjs(fromField.value, DATE_FORMAT).toDate();
                    toPicker.setMinDate(startDate);
                }
            },
            onSelect: function() {
                var selectedDate = new Date(this.getDate());
                var maxEndDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()); // Today
                fromPicker.setMaxDate(maxEndDate);
                var startDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()-1 );
                if (!fromField.value && toField.value) {
                    fromPicker.setDate(startDate);
                }
            }
        });
    }
}
