import $ from 'jquery';


  featureContent();

  //when resize's the browser window , toggling between nagvbar search and refine-search-filter.
  let lastwidth = $(window).width();
  $(window).on('resize', function () {
    paginationMobileView();
    resultsDataNotices();
    if ($(this).width() !== lastwidth && $(this).width() > 767) {
      $('#refine-filter-desktop').show();
      $('.mb-search').css('display', 'none');
      $('#header').css('display', 'block');
      document.body.style.height = '';
      document.body.style.overflow = "";
      lastwidth = $(this).width();
    } else if ($(this).width() !== lastwidth && $(this).width() <= 767) {
      $('#refine-filter-desktop').hide();
      $('.mb-search').css('display', 'block');
      $('#header').css('display', 'block');
      lastwidth = $(this).width();
    }
  });

  $("#filterButton_mobile").on("click", function () {
    $('#refine-filter-desktop').show();
    $('#header, .mb-search').css('display', 'none');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if ($('#refine-filter-desktop').css('display', 'block')) {
      document.body.style.height = '100%';
      document.body.style.overflow = "hidden";
      $('.navigation').css('z-index','9');
    } else {
      document.body.style.height = '';
      document.body.style.overflow = "";
      $('.navigation').css('z-index','');
    }
  });



  if ($(window).width() <= 767) {
    $('#refine-filter-desktop').hide();
    resultsDataNotices();
    paginationMobileView();
  } else {
    $('#refine-filter-desktop').show();
    navbarFilterScroll();
  }

  $("span#refine-close").on("click", function () {
    $('#refine-filter-desktop').hide();
    $('#header, .mb-search').css('display', 'block');
    document.body.style.height = '';
    document.body.style.overflow = '';
  });

  $('.clearFilter').on('click', () => {
    $('.navbar-search-form').trigger("reset");
    $(".navbar-search-form").find('input').val('');
    $('select').prop('selectedIndex', 0);
  })

  $('.clearRefineFilter').on('click', () => {
    $('.refine-search-filter-form').trigger("reset");
    $('.refine-search-filter-form').find('input').val('');
    $('select').prop('selectedIndex', 0);
  })

  $('#filterList').on('click', () => {
    $('#showFilterList').toggle();
  })

  $('#resultDropDown').on('click', () => {
    $('.resultDropDown').addClass('active');
  });
  $('.backto-Top').on('click', () => {
    $("html, body").animate({
      scrollTop: 0
    }, 1000);
  })

  $('.funeral_icon_list img').on('click', function () {
    $('.funeral_icon_list').children().removeClass('active');
    $(this).toggleClass('active');
  })

  function navbarFilterScroll(){
    let lastScroll = 0;
    $(window).scroll(function () {
      const navbarscroll = {
        breakpoint : 285,
        currentScroll : window.scrollY
      }
      if ($(this).scrollTop() > navbarscroll.breakpoint) {
        navbarScrollHandler(navbarscroll.currentScroll, lastScroll);
      } else {
        $("#funeral-desktop-ad, #fd-desktop-ad").css({"position": "relative","top": '0px'});
        $("#navbar-search-filter").css({"position": "relative"}).addClass('is-visible');
      }
      lastScroll = navbarscroll.currentScroll;
    });
  }

  function navbarScrollHandler(currentScroll, lastScroll){
    let $navbarEl = $("#navbar-search-filter");
    let $fdDesktopEl = $("#funeral-desktop-ad, #fd-desktop-ad");
      if(currentScroll < lastScroll){
        $navbarEl.css({"position": "sticky","top": 0,"z-index":101}).delay(500).addClass('is-visible');
        showFilterCurrentScroll($fdDesktopEl);
      }else{
        isNavBarFilterCondition(currentScroll, lastScroll, $navbarEl, $fdDesktopEl);
      }
  }
  function showFilterCurrentScroll($fdDesktopEl){
    if($('#showFilterList').css('display') === 'block'){
      $fdDesktopEl.css({"position": "sticky","top": '250px'});
    }else{
      $fdDesktopEl.css({"position": "sticky","top": '130px'});
    }
  }
  function isNavBarFilterCondition(currentScroll, lastScroll, $navbarEl, $fdDesktopEl){
    if(isNavbarSticky(currentScroll, lastScroll)){
      $navbarEl.css({"position": "sticky","top": 0,"z-index":101}).delay(500).addClass('is-visible');
      $fdDesktopEl.css({"position": "sticky","top": '130px'});
    }else{
      showFilterList(currentScroll, lastScroll, $navbarEl, $fdDesktopEl);
    }
  }
  function showFilterList(currentScroll, lastScroll, $navbarEl, $fdDesktopEl){
    if($('#showFilterList').css('display') === 'block'){
      isShowFilterOpen(currentScroll, lastScroll, $navbarEl, $fdDesktopEl);
    }else{
      $navbarEl.removeClass('is-visible').css({"position": "relative"});
      $fdDesktopEl.css({"position": "sticky","top": '30px'});
    }
  }
  function isShowFilterOpen(currentScroll, lastScroll, $navbarEl, $fdDesktopEl){
    if(currentScroll - $('#showFilterList').height() == lastScroll){
      $navbarEl.css({"position": "sticky","top": 0,"z-index":101}).delay(500).addClass('is-visible');
      $fdDesktopEl.css({"position": "sticky","top": '250px'});
    }else{
      $navbarEl.removeClass('is-visible').css({"position": "relative"});
      $fdDesktopEl.css({"position": "sticky","top": '30px'});
    }
  }
  function isNavbarSticky(currentScroll, lastScroll){
     return !!(currentScroll < lastScroll && $('#showFilterList').css('display') === 'none');
  }
  function resultsDataNotices() {
    let results = $('.funeral-notices-list .funeral-container .header span').text().split(' ');
    if (results != '' && results != undefined && results && results[1].length >= 5 && $(window).width() <= 767 ) {
      $('.funeral-notices-list .funeral-container .header').css({ 'grid-template-columns': 'auto', 'height': 'auto','grid-template-areas': 'none'});
      $('.funeral-notices-list .funeral-container .header span').css({ 'width': '100%', 'height': 'auto', 'padding': '4px' });
    } else {
      $('.funeral-notices-list .funeral-container .header').removeAttr("style");
      $('.funeral-notices-list .funeral-container .header span').removeAttr("style");
    }
  }


  /**
   * pagination for mobile view
   */
  function paginationMobileView() {
    if (($(window).width() <= 767)) {
      let pagination_li_active = '.page-navigation li.active';
      $('.page-navigation li').css('display', 'none');
      $('.page_previous').css('display', 'block');
      $('.page_next').css('display', 'block');
      $('.pagination-gap').css('display', 'block');
      paginationNavActive(pagination_li_active);
    }else {
      $('.page-navigation li').css('display', 'block');
    }
  }

  function paginationNavActive(pagination_li_active){
    if ($(pagination_li_active).hasClass('page_previous')) {
      $(window).width() <= 420 ?  $(pagination_li_active).nextAll().slice(0, 2).css('display', 'block') : $(pagination_li_active).nextAll().slice(0, 3).css('display', 'block');
    } else if ($('.page-navigation li').hasClass('disabled') && !($('.page-navigation li').hasClass('page_next'))) {
      $(pagination_li_active).css('display', 'block');
      $(pagination_li_active).next("li").css('display', 'block');
      $(window).width() <= 420 ?  $(pagination_li_active).prevAll().slice(0, 1).css('display', 'block') : $(pagination_li_active).prevAll().slice(0, 2).css('display', 'block');
    } else if (!($(pagination_li_active).hasClass('page_next')) || !($(pagination_li_active).hasClass('page_prevous'))) {
      paginationNavigation(pagination_li_active);
      $(pagination_li_active).css('display', 'block');
      $(pagination_li_active).prev("li").css('display', 'block');
      $(window).width() <= 420 ?  $(pagination_li_active).nextAll("li").slice(0, 0).css('display', 'block') : $(pagination_li_active).nextAll("li").slice(0, 1).css('display', 'block');
    }
  }
  function paginationNavigation(pagination_li_active){
    if ((parseInt($(pagination_li_active).data('pagenumber')) == 3) || parseInt($(pagination_li_active).data('pagenumber')) == 4) {
        $(".page-navigation li:nth-child(2)").remove();
        $(".page-navigation li:nth-child(1)").after($("<li class='pagination-gap'>").text("..."));
    } else if ((parseInt($(pagination_li_active).data('endpagenumber')) == 3) || parseInt($(pagination_li_active).data('endpagenumber')) == 4) {
      $(".page-navigation li:nth-last-child(2)").remove();
      $(".page-navigation li:nth-last-child(1)").before($("<li class='pagination-gap'>").text("..."));
    } else if ((parseInt($(pagination_li_active).data('pagenumber')) == 1)) {
      $(pagination_li_active).css('display', 'block');
      $(pagination_li_active).prev("li").css('display', 'block');
      $(window).width() <= 420 ?  $(pagination_li_active).nextAll("li").slice(0, 1).css('display', 'block') : $(pagination_li_active).nextAll("li").slice(0, 2).css('display', 'block');
    }
  }

  function featureContent(){
    if (document.getElementsByClassName("fd-list-item")[0]) {
      $('.fd-feature-content').css('display', 'block');
    } else {
      $('.fd-feature-content').css('display', 'none');
    }
  }
